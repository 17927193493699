<template>
  <section class="post-info">
    <div class="inner">
      <h1>{{ TITLE }}</h1>
      <div class="hr top"></div>
      <video v-if="VIDEO !== ''" :src="VIDEO" controls autoplay muted></video>
      <img v-else :src="MAIN_IMAGE" alt="">
      <p>{{ TEXT }}</p>
      <img v-if="VIDEO !== ''" :src="MAIN_IMAGE" alt="" @click="onImgClick(-1)">
      <img v-if="HAS_TWO_IMAGES" :src="SECOND_IMAGE" alt="" @click="onImgClick(-2)">
      <template v-else>
        <div class="other-post-images" v-if="HAS_THREE_OR_MORE_IMAGES">
          <img v-for="(img, i) in OTHER_IMAGES" :src="'/b/' + img" alt="" :key="i" @click="onImgClick(i)">
        </div>
      </template>
      <div class="hr bottom"></div>
      <h2>Related posts</h2>
      <div class="related">
        <Post v-for="p in relatedPosts" :key="p.id" :post="p"></Post>
      </div>
    </div>
    <transition name="img-info" appear>
      <div class="img-container" @click.self="closeImg" v-if="imageClicked">
        <img :src="'/b/' + OTHER_IMAGES[imageIndex] || ''" :alt="OTHER_IMAGES[imageIndex] || 'empty'" v-if="imageIndex >= 0">
        <img :src="imageIndex === - 1 ? MAIN_IMAGE : SECOND_IMAGE" alt="" v-else>
        <template v-if="OTHER_IMAGES.length > 1 && imageIndex >= 0">
          <div class="btn" @click="switchImg" v-if="imageIndex < OTHER_IMAGES.length - 1">
            <img src="../assets/img/arrow.svg" alt="">
          </div>
          <div class="btn translated" @click="switchImg(true)" v-if="imageIndex > 0">
            <img src="../assets/img/arrow.svg" alt="">
          </div>
        </template>
      </div>
    </transition>
  </section>
</template>

<script>
import api from "../api/api";
import Post from "../components/Post";

export default {
  name: "PostInfo",
  components: {Post},
  data() {
    return {
      post: {},
      postImages: [],
      relatedPosts: [],
      imageClicked: false,
      imageIndex: -1
    }
  },
  computed: {
    TITLE() {
      if (!this.post.id) return ''
      return this.post.title[this.$store.getters.LANG]
    },
    TEXT() {
      if (!this.post.id) return ''
      return this.post.text[this.$store.getters.LANG]
    },
    MAIN_IMAGE() {
      if (!this.post.id) return ''
      if (this.postImages.length) return '/b/' + this.postImages[0]
      return ''
    },
    SECOND_IMAGE() {
      if (!this.post.id) return ''
      if (this.postImages.length > 1) return '/b/' + this.postImages[1]
      return ''
    },
    OTHER_IMAGES() {
      return this.postImages.slice(1)
    },
    VIDEO() {
      if (!this.post.id) return ''
      if (this.post.video) return '/b/' + this.post.video
      return ''
    },
    HAS_TWO_IMAGES() {
      return this.postImages.length === 2
    },
    HAS_THREE_OR_MORE_IMAGES() {
      return this.postImages.length >= 3
    },

  },
  created() {
    // api.getData('get/b', true, this.$route.params.id).then(r => {
    //   console.log('post resp: ', r)
    //   if (!r.data.error) {
    //     this.post = r.data.post || {}
    //   }
    // })
    api.getData('get/b').then(r => {
      if (!r.data.error) {
        const posts = r.data.posts || []
        for (let i = 0; i < posts.length; i++) {
          if (posts[i].id === +this.$route.params.id) {
            this.post = posts[i]
            if (this.post.images) this.postImages = this.post.images.split(',')
          }
          else this.relatedPosts.push(posts[i])
        }
      }
    })
  },
  methods: {
    onImgClick(index) {
      this.imageIndex = index
      this.imageClicked = true
    },
    closeImg() {
      this.imageClicked = false
      this.imageIndex = -1
    },
    switchImg(toPrev) {
      if (toPrev === true) this.imageIndex--
      else this.imageIndex++
    }
  }
}
</script>

<style scoped lang="scss" src="../assets/scss/post_info.scss"></style>
